import React from "react";
import "../NavigationBar/NavBar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";

import logo from "../../logindashboard/DDXLogo.svg";

import { ReactComponent as Logoutlogo } from "../NavigationBar/LogoutLogo.svg";

export default function NavBar() {
  return (
    <Navbar className="navbar navbar-expand-md" variant={"dark"}>
      <Container fluid className="d-flex align-items-center">
        <Navbar.Brand
          className="navbar-brand"
          bs-navbar-brand-margin-end={"0rem"}
          href="/"
        >
          <img src={logo} height="24" />
        </Navbar.Brand>
        <Nav.Item
          fluid="true"
          className="Nav-text-body "
          bs-gutter-x={"0rem"}
          bs-gutter-y={"0rem"}
        >
          <div className="rectangle3"></div>
          <div className="rectangle4"></div>
          <div className="Nav-text-one">Siemens AG</div>
          <div className="rectangle5"></div>
          <div className="rectangle6"></div>
          <div className="Nav-text-two">Incident Management</div>
        </Nav.Item>

        <div className="rectangle7"></div>
      </Container>
      <Nav.Link as={Link} to="/logout" className="LogoutlogoLayout">
        <Logoutlogo />
      </Nav.Link>
    </Navbar>
  );
}
