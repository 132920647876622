import React, { useState } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

import styles from "./CustomToggle.module.scss";

export default function CustomToggle({ children, eventKey }) {
  const [collapse, setCollapse] = useState(true);

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setCollapse(!collapse);
  });

  const handleIcon = (state) => {
    return state ? "arrowSingleRight" : "arrowSingleDown";
  };

  return (
    <button
      type="button"
      className={styles.toggle_btn}
      onClick={decoratedOnClick}
    >
      <span
        aria-hidden="true"
        className={styles.toggle_btn + " iconMdsp " + handleIcon(collapse)}
      />

      {children}
    </button>
  );
}
