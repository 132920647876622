import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppRoutes from "./components/routes/AppRoutes";
import SpinnerApp from "./components/spinners/SpinnerApp";
import { AuthProvider } from "./context/useAuth";
import { Toaster } from "react-hot-toast";
import "./interceptors/JwtInterceptor";
import Toast from "./components/shared/toast/Toast";

function App() {
  const navigate = useNavigate();
  let [count, setCount] = useState(0);
  useEffect(() => {
    axios.interceptors.request.use((req) => {
      if (!req.isBackground) {
        setCount(count + 1);
      }
      return req;
    });

    axios.interceptors.response.use(
      (response) => {
        if (!response.isBackground) {
          setCount(count - 1);
        }
        return response;
      },
      (error) => {
        if (!error.isBackground) {
          setCount(count - 1);
        }
        if (error.response && error.response.status === 401) {
          return navigate("/login");
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <div className="ddx-cloud-app" data-testid="ddx-cloud-app-test">
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
      {count > 0 && <SpinnerApp />}

      <Toast />
    </div>
  );
}

export default App;
