import React, { useContext, useState } from "react";
import { TOKEN } from "../constants/Constant";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const jwtToken = sessionStorage.getItem(TOKEN);
  const [token, setToken] = useState({ token: jwtToken });

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
}
