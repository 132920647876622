export const getClaimStateColor = (state) =>
  ({
    Created: "rgba(var(--color-primary),1)",
    Published: "rgba(var(--color-success),1)",
    Archived: "rgba(var(--color-warning),1)",
    Canceled: "rgba(var(--color-error),1)",
  }[state]);

export function groupByState(data) {
  const groups = {};
  data.forEach((item) => {
    if (!groups[item.State]) {
      groups[item.State] = {
        id: item.State.toLowerCase(),
        label: item.State,
        value: 0,
        color: getClaimStateColor(item.State),
      };
    }
    groups[item.State].value += 1;
  });

  return Object.values(groups);
}
