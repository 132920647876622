import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "../../../features/claims/claimsSlice";
import "./Dropzone.scss";

function Dropzone(props) {
  const dispatch = useDispatch();
  const selectedClaim = useSelector((state) => state.claims.selectedItem);

  const onDrop = useCallback((acceptedFiles) => {
    dispatch(
      uploadFile({
        acceptedFiles,
        ClaimID: selectedClaim?.ClaimID,
      })
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <section className="dropzone">
      <div {...getRootProps({ className: "dropzone__root" })}>
        <input {...getInputProps()} />
        <span className="iconMdsp dropzone" aria-hidden="true" />

        <p>Drag and drop files here, or click to browse files</p>
      </div>
    </section>
  );
}

export default Dropzone;
