import React, { useEffect, useState } from "react";
import "./Home.scss";
import Chart from "../shared/chart/Chart";
import Card from "../shared/card/Card";
import Notifications from "../notifications/Notifications";
import Claims from "./claims/Claims";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Pager from "../shared/pager/Pager";

const PAGE_SIZE = 5;

const invoiceData = [
  {
    id: "new",
    label: "New",
    value: 20,
    color: "hsl(193, 47%, 26%)",
  },
  {
    id: "unpayed",
    label: "Unpayed",
    value: 20,
    color: "hsl(47, 100%, 50%)",
  },
  {
    id: "payed",
    label: "Payed",
    value: 60,
    color: "hsl(211, 100%, 45%)",
  },
];

function Home() {
  const notificationCount = useSelector(
    (state) => state.notifications.items.length
  );
  const claimCount = useSelector((state) => state.claims.items.length);

  const [page, setPage] = useState(1);

  return (
    <div id="payment-management" className="dynamic-payments ddx-content">
      <div
        className="container-fluid payment-details d-flex gap-2 py-3 "
        data-testid="payment-management"
      >
        <div className="cards cards--simpleGrid w-100">
          <div className="cards__row">
            <div className="cards__col">
              <Card
                title="Dynamic payment"
                subtitle="Billing status"
                footer={
                  <a className="button button--primary w-100" href="#">
                    View payment reports
                  </a>
                }
              >
                <Chart data={invoiceData} />
              </Card>
            </div>
            <div className="cards__col">
              <Card
                title="Incident reporting"
                subtitle="Claim status"
                count={claimCount}
                footer={
                  <Link
                    className="button button--primary w-100"
                    to="claim-list"
                  >
                    View incident reports
                  </Link>
                }
              >
                <Claims />
              </Card>
            </div>
            <div className="cards__col">
              <Card
                title="Reported alarm conditions"
                subtitle="Alarm status"
                count={notificationCount}
                Pager={() =>
                  notificationCount ? (
                    <Pager
                      itemsPerPage={PAGE_SIZE}
                      currentPage={page}
                      onPageChange={setPage}
                      totalItems={notificationCount}
                      //onPageChange={setPage}
                    />
                  ) : null
                }
              >
                <Notifications
                  page={page}
                  setPage={setPage}
                  pageSize={PAGE_SIZE}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
