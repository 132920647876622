import React from "react";
import PropTypes from "prop-types";

function Button({ label, icon, cssClass, callback }) {
  const t = (p) => p;

  return (
    <button className={"button " + cssClass} onClick={callback}>
      <span aria-hidden="true" className={"iconMdsp " + icon}></span>
      {t(label)}
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  cssClass: PropTypes.string,
  callback: PropTypes.func,
};

export default Button;
