import React from "react";
import { Link } from "react-router-dom";

export const Breadcrumb = () => {
  const paths = window.location.pathname
    .split("/")
    .filter((p) => p !== "" && p !== "#");
  const toCamelCase = (str) => {
    let newStr = "";
    if (str) {
      let wordArr = str.split(/[-_]/g);
      for (let i in wordArr) {
        newStr +=
          " " + wordArr[i].charAt(0).toUpperCase() + wordArr[i].slice(1);
      }
    }
    return newStr;
  };

  return (
    <ul className="breadcrumb">
      <li
        style={{ color: "#000000" }}
        className={`breadcrumb__item ${!paths?.length ? "is-current" : ""}`}
      >
        <Link to="/">Incident Management</Link>
      </li>

      {paths.map((breadcrumb, index) => {
        const url = `/${paths.slice(0, index + 1).join("/")}`;
        return (
          <li
            key={index}
            className={`breadcrumb__item ${
              index === paths.length - 1 ? "is-current" : ""
            }`}
          >
            <Link to={url}>{toCamelCase(breadcrumb)}</Link>
          </li>
        );
      })}
    </ul>
  );
};
