import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import notificationReducer from "../features/notifications/notificationsSlice";
import assetsReducer from "../features/assets/assetsSlice";
import claimsReducer from "../features/claims/claimsSlice";
import { toast } from "react-hot-toast";

const errorToastMiddleware = (store) => (next) => (action) => {
  if (action.type.endsWith("/rejected") && action.payload) {
    toast.error(action.payload);
  }

  return next(action);
};

const logger = createLogger();

export const store = configureStore({
  reducer: {
    notifications: notificationReducer,
    assets: assetsReducer,
    claims: claimsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(errorToastMiddleware, logger),
});
