import React from "react";
import Select from "react-select";

const dot = (color = "transparent") => ({
  display: "flex",

  ":before": {
    alignSelf: "center",
    backgroundColor: color,
    borderRadius: 10,
    content: '""',
    display: "block",
    marginRight: 4,
    marginLeft: 8,
    height: 10,
    width: 10,
  },
});

const colorStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = data.color;
    return {
      ...styles,
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color
          : undefined,
      },
      ...dot(data.color),
    };
  },
  multiValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const Filter = ({ tags, setSelectedTags }) => {
  const handleChange = (selectedOptions, triggeredAction) => {
    if (triggeredAction.action === "clear") {
      setSelectedTags([]);
    } else {
      setSelectedTags(selectedOptions);
    }
  };

  return (
    <Select
      isMulti
      isClearable
      placeholder="Filter claims"
      name="filter"
      options={tags}
      className="basic-multi-select mb-2"
      classNamePrefix="select"
      onChange={handleChange}
      styles={colorStyles}
    />
  );
};

export default Filter;
