import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Link, useLocation, useNavigate } from "react-router-dom";

import CustomToggle from "../custom-toggle-bootstrap/CustomToggle";

import { statusClass } from "../../utils/ContractUtils";
import { useSelector, useDispatch } from "react-redux";
import { createClaim } from "../../features/claims/claimsSlice";

import "./Alarms.scss";
import Button from "../shared/button/Button";
import { closeNotification } from "../../features/notifications/notificationsSlice";
import Dialog from "../shared/dialog/Dialog";
import { toast } from "react-hot-toast";

function Alarms() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state: selectedNotification } = useLocation();

  const [dismiss, setDismiss] = useState(false);

  const assets = useSelector((state) => state.assets.items);

  const getStatusClass = (status) => {
    return statusClass(status);
  };

  const createHandler = (NotificationID, Payload) =>
    dispatch(
      createClaim({
        Type: "dff28153-61dc-4aaa-a5f4-b19e5f70a5e9",
        Title: `Generated from Notification #${NotificationID}`,
        RelatedAssets: Payload.AssetID,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(closeNotification(NotificationID));
        navigate("/claim-list/create-claim");
      })
      .catch((rejectedValueOrSerializedError) => {});

  const closeHandler = () => {
    if (selectedNotification) {
      dispatch(closeNotification(selectedNotification.NotificationID))
        .unwrap()
        .then(() => {
          navigate("/");
          toast.success("Notification dismissed successfully");
        })
        .catch((error) => {});
    }
  };

  const alarmsHtml = () => {
    if (selectedNotification) {
      const { NotificationID, Payload, Detail } = selectedNotification;

      return (
        <Accordion key={`alarm-${NotificationID}`} data-testid="accordion">
          <Card>
            <Card.Header>
              <div className="accordion-header">
                <div className={`column ${getStatusClass(0)}`}>
                  <CustomToggle eventKey={NotificationID} />
                </div>
                <div className="column">
                  <p className="content d-flex gap-1">
                    <span>
                      {new Date(Detail.TimeStamp).toLocaleDateString("en-GB")}
                    </span>
                    <span>
                      {new Date(Detail.TimeStamp).toLocaleTimeString("en-GB")}
                    </span>
                  </p>
                  <p className="header">{Detail.Message}</p>
                </div>
                <div className="column flex-1">
                  <p className="content">Asset</p>
                  <p className="header">
                    {assets ? assets[Payload?.AssetID]?.Name : null}
                  </p>
                </div>
                <div className="column">
                  <p className="content">Severity</p>
                  <p className="header">{Detail.Severity}</p>
                </div>
                <div className="column" />
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey={NotificationID}>
              <div className="card-body">
                <div className="column" />

                <div className="column">
                  <p className="content">Comment</p>
                  <p className="header">{Detail.Comment || "-"}</p>
                </div>
                <div className="d-flex pe-4 pt-3 flex-1 align-items-start justify-content-end">
                  <Button
                    label={"Dismiss"}
                    cssClass="button--secondary"
                    //callback={() => closeHandler(NotificationID)}
                    callback={() => setDismiss(true)}
                  />
                  <Button
                    label={"Create incident"}
                    cssClass="button--primary"
                    callback={() => createHandler(NotificationID, Payload)}
                  />
                </div>
              </div>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    } else {
      return (
        <div className="emptyState emptyState--frameless">
          <div className="emptyState__visual">
            <span aria-hidden="true" className="iconMdsp filled asset" />
          </div>

          <h2 className="emptyState__title">No notifications selected</h2>
          <div className="emptyState__description">
            Please select a notification from home page
          </div>
          <div className="emptyState__callToAction">
            <Link to="/">Go to home</Link>
          </div>
        </div>
      );
    }
  };

  return (
    <div id="alarm-list" className="dynamic-payments ddx-content">
      <div className="container-fluid py-3" data-testid="payment-management">
        {/* <FilterContract
          customerOptions={customerOptions}
          callback={{ handleFilterFormChange }}
        /> */}
        {alarmsHtml()}
        {dismiss ? (
          <Dialog
            title="Dismiss notification"
            description="Are you sure you want to dismiss the notification? You will not be able to access the notification after dismissing it."
            primaryText="Dismiss"
            primaryAction={closeHandler}
            closeText="Go back"
            onClose={() => setDismiss(false)}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Alarms;
