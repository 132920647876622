// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons

import { faCode, faHighlighter } from '@fortawesome/free-solid-svg-icons';

library.add( 
  faCode,
  faHighlighter
  // more icons go here
);