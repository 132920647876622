import React, { Component } from "react";
import { animate } from "./animation";

import "mdbreact/dist/css/mdb.css";
import { ReactComponent as DDXLogo } from "./DDXLogo.svg";
import "./fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import "./App.css";
import { Link } from "react-router-dom";

function LogIn() {
  if (process.env.REACT_APP_STAGE === "rel") {
    window.location.href =
      "https://demoddx.auth.eu-central-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=61n6g08925tvempu71tfvv5cfv&redirect_uri=https://mu.claimmanagement.tpoc.datadrivenx.com/evalauth&scope=IOTbasedInsurance/Contracts.Read+IOTbasedInsurance%2FContracts.Write+IOTbasedInsurance%2FFullAccess+openid";
  }

  if (process.env.REACT_APP_STAGE === "debug") {
    window.location.href =
      "https://demoddx.auth.eu-central-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=61n6g08925tvempu71tfvv5cfv&redirect_uri=https://localhost:3000/evalauth&scope=IOTbasedInsurance/Contracts.Read+IOTbasedInsurance%2FContracts.Write+IOTbasedInsurance%2FFullAccess+openid";
  }
}

class Login extends Component {
  componentDidMount() {
    sessionStorage.clear();
    const collection = document.getElementsByClassName(
      "particle-network-animation"
    );
    const elem = collection[0];
    animate(elem);
  }

  render() {
    return (
      <div className="particle-network-animation container2" width="100%">
        <div className="adaptedAppHeader2">
          <Link to="/">
            <DDXLogo />
          </Link>
        </div>
        <div className="centered">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={LogIn}
          >
            Login
            <FontAwesomeIcon
              icon={faSignIn}
              style={{ padding: "0px 0px 0px 10px" }}
            />
          </button>
        </div>
      </div>
    );
  }
}

export default Login;
