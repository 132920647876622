import React from "react";
import Chart from "../../shared/chart/Chart";
import { useSelector } from "react-redux";
import { groupByState } from "../../../utils/ClaimUtils";

function Claims() {
  const claims = useSelector((state) => state.claims.items);
  const loading = useSelector((state) => state.claims.loading);
  const incidents = groupByState(claims);

  if (loading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <span className="busyIndicator is-shown">
          <span className="busyIndicator__ring" />
        </span>
      </div>
    );
  }
  return incidents ? <Chart data={incidents} icon="alert" /> : null;
}

export default Claims;
