import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import "./ClaimInformation.scss";
import {
  archiveClaim,
  cancelClaim,
  downloadFile,
  publishClaim,
} from "../../features/claims/claimsSlice";
import { toast } from "react-hot-toast";

function ClaimReport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedClaim = useSelector((state) => state.claims.selectedItem);
  const assets = useSelector((state) => state.assets.items);
  const loadingHours = useSelector((state) => state.claims.loadingHours);
  const loadingLogs = useSelector((state) => state.claims.loadingLogs);
  const loading = useSelector((state) => state.claims.loading);
  const loadingData = useSelector((state) => state.claims.loadingData);
  const loadingAttributes = useSelector(
    (state) => state.claims.loadingAttributes
  );
  const date = new Date(selectedClaim?.CreationTime).toLocaleDateString(
    "en-GB"
  );
  const time = new Date(selectedClaim?.CreationTime).toLocaleTimeString(
    "en-GB"
  );
  const relatedAssets = selectedClaim?.RelatedAssets;
  const { ClaimID, Attributes, ContractID, Attachments, State } = selectedClaim;
  const { UserTitle, UserDescription } = Attributes;

  const { Metrics, UserAttachments } = Attachments?.reduce(
    (acc, curr) => {
      if (curr.description?.startsWith("System attached data-file")) {
        acc.Metrics.push(curr);
      } else {
        acc.UserAttachments.push(curr);
      }
      return acc;
    },
    { Metrics: [], UserAttachments: [] }
  );

  const downloadHandler = (DownloadKey, FileName) => {
    dispatch(downloadFile({ ClaimID, DownloadKey, FileName }));
  };

  const publishHandler = () =>
    dispatch(publishClaim(selectedClaim?.ClaimID))
      .unwrap()
      .then((originalPromiseResult) => {
        navigate("/");
        toast.success("Claim published successfully");
      });

  const archiveHandler = () =>
    dispatch(archiveClaim(selectedClaim?.ClaimID))
      .unwrap()
      .then((originalPromiseResult) => {
        navigate("/");
        toast.success("Claim archived successfully");
      });

  const cancelHandler = () =>
    dispatch(cancelClaim(selectedClaim?.ClaimID))
      .unwrap()
      .then((originalPromiseResult) => {
        navigate("/");
        toast.success("Claim canceled successfully");
      });
  const [showMore, setShowMore] = useState(false);
  return selectedClaim ? (
    <div className="claim-form">
      <div className="information-container d-flex gap-5">
        <div className="keyValueWrapper">
          <div className="keyValueWrapper__keyValue keyValueWrapper__keyValue--reverse">
            <div className="keyValue__value">
              {assets[relatedAssets[0]].Name}
            </div>
            <div className="keyValue__key">Affected asset</div>
          </div>
        </div>
        <div className="keyValueWrapper">
          <div className="keyValueWrapper__keyValue keyValueWrapper__keyValue--reverse">
            <div className="keyValue__value">{date}</div>
            <div className="keyValue__key">Date of the incident</div>
          </div>
        </div>
        <div className="keyValueWrapper">
          <div className="keyValueWrapper__keyValue keyValueWrapper__keyValue--reverse">
            <div className="keyValue__value">{time}</div>
            <div className="keyValue__key">Time of the incident</div>
          </div>
        </div>
        <div className="keyValueWrapper">
          <div className="keyValueWrapper__keyValue keyValueWrapper__keyValue--reverse">
            <div className="keyValue__value">{ContractID}</div>
            <div className="keyValue__key">Contract ID</div>
          </div>
        </div>
      </div>
      <div className="keyValueWrapper">
        <div className="keyValueWrapper__keyValue keyValueWrapper__keyValue--reverse">
          <div className="keyValue__value">{UserTitle || "-"}</div>
          <div className="keyValue__key">Title</div>
        </div>
      </div>
      <div className="keyValueWrapper">
        <div className="keyValueWrapper__keyValue keyValueWrapper__keyValue--reverse">
          <div className="keyValue__value">{UserDescription || "-"}</div>
          <div className="keyValue__key">Description</div>
        </div>
      </div>
      <div className="keyValueWrapper">
        <div className="keyValueWrapper__keyValue keyValueWrapper__keyValue--reverse">
          <div className="keyValue__value">
            <div className="d-flex flex-wrap gap-4">
              {UserAttachments?.length
                ? UserAttachments?.map(({ filename, downloadkey }) => (
                  <button
                    key={downloadkey}
                    className="button button--secondaryContentAction"
                    onClick={() => downloadHandler(downloadkey, filename)}
                  >
                    <span className="iconMdsp filetypeDefault" />
                    {filename}
                  </button>
                ))
                : "No attachments found"}
            </div>
          </div>
          <div className="keyValue__key">Attachments</div>
        </div>
      </div>
      <div className="keyValueWrapper">
        <div className="keyValueWrapper__keyValue keyValueWrapper__keyValue--reverse">
          <div className="keyValue__value">
            <div className="d-flex flex-wrap gap-4">
              {loadingData ? (
                <div className="gap-2 justify-content-center align-items-center d-flex">
                  <div className="busyIndicator is-shown">
                    <div className="busyIndicator__ring busyIndicator__ring--xsmall" />
                  </div>
                  loading metrics
                </div>
              ) : Metrics?.length ? (
                Metrics?.map(({ filename, downloadkey }) => (
                  <button
                    key={downloadkey}
                    className="button button--secondaryContentAction"
                    onClick={() => downloadHandler(downloadkey, filename)}
                  >
                    <span className="iconMdsp filetypeDefault" />
                    {filename}
                  </button>
                ))
              ) : (
                "No metrics found"
              )}
            </div>
          </div>
          <div className="keyValue__key">Reported metrics</div>
        </div>
      </div>

      {selectedClaim.claimPreCheckDetails && selectedClaim.claimPreCheckDetails.message === undefined && selectedClaim.claimPreCheckDetails.explanation !== undefined ?
        <div className="information-container-precheck">
          <div className="keyValue__key prechek_det_head">Pre-Check Details of Claim</div>
          <div className="keyValue__value precheck_details"><span className="precheck_det_span">Confidence</span> : {selectedClaim.claimPreCheckDetails.confidence}</div>
          <div className="keyValue__value precheck_details" style={selectedClaim.claimPreCheckDetails.result ? { "backgroundColor": "#23e123" } : { "backgroundColor": "#ff6969" }}><span className="precheck_det_span">Is Claim Insured</span> : {selectedClaim.claimPreCheckDetails.result}</div>
          <div className="keyValue__value precheck_details"><span className="precheck_det_span">Claim Policy Paragraph</span> : {selectedClaim.claimPreCheckDetails.paragraph}</div>
          <div className="keyValue__value precheck_details"><span className="precheck_det_span">Claim Policy Paragraph Details</span> : {selectedClaim.claimPreCheckDetails.paragraph_detail}</div>
          <div className="keyValue__value precheck_details"><span className="precheck_det_span">Claim Check Explanation</span> : {showMore ? selectedClaim.claimPreCheckDetails.explanation : selectedClaim.claimPreCheckDetails.explanation.substring(0, 200) + (selectedClaim.claimPreCheckDetails.explanation.length > 200 ? "..." : "")}
            {selectedClaim.claimPreCheckDetails.explanation.length > 200 ? <button className="show_more" onClick={(e) => { e.preventDefault(); setShowMore(!showMore); }}>{showMore ? "Show less" : "Show more"}</button> : ""}
          </div>
        </div>
        : (selectedClaim.claimPreCheckDetails && selectedClaim.claimPreCheckDetails.message !== undefined ?
          <div className="information-container-precheck">
            <div className="keyValue__key prechek_det_head">Pre-Check Details of Claim</div>
            <div className="keyValue__value precheck_details"><span className="precheck_det_span">Error Occured</span> : {selectedClaim.claimPreCheckDetails.message}</div>
          </div> :
          <div className="keyValue__key prechek_det_head">{selectedClaim.claimPreCheckDetails}</div>
        )}

      {State === "Created" ? (
        <div className="footer d-flex justify-content-end gap-2 align-items-center align-self-end">
          <button
            className={`button button--ghost ${loadingData ? "is-disabled" : ""
              }`}
            onClick={cancelHandler}
            disabled={loadingData}
          >
            Cancel claim
          </button>
          <button
            className={`button button--secondary ${loadingData ? "is-disabled" : ""
              }`}
            onClick={archiveHandler}
            disabled={loadingData}
          >
            Archive claim
          </button>
          <button
            className={`button button--primary ${loadingData ? "is-disabled" : ""
              }`}
            onClick={publishHandler}
            disabled={loadingData}
          >
            <span className="iconMdsp email" />
            Send Report
          </button>
        </div>
      ) : null}
    </div>
  ) : (
    <Navigate to="/claim-list" />
  );
}

export default ClaimReport;
