import axios from "axios";
import { ASSET_ID, BASE_URL, METRIC_ID } from "../constants/Constant";

export const fetchMachineUsers = async () => {
  return {
    data: {
      id: 1,
      companyName: "Siemens",
      machineUsers: [
        {
          id: 1,
          companyName: "Siemens AG EWN",
        },
        {
          id: 2,
          companyName: "Nikolaus Sorg GmbH & Co. KG",
        },
      ],
    },
  };
};

export const fetchAssetHealthInfo = async () => {
  return axios.get(`${BASE_URL}/Query?AssetID=${ASSET_ID}&Metric=${METRIC_ID}`);
};

// Notifications
export const fetchNotifications = async () => {
  return axios.get(`${BASE_URL}/Notifications/V1/Get`, {
    isBackground: true,
  });
};

export const fetchNotificationDetail = async (
  AssetID,
  Metric = "964739fb-1c67-4a06-a37c-c72ff4462366",
  From,
  To
) => {
  return axios.get(
    `${BASE_URL}/Query?AssetID=${AssetID}&Metric=${Metric}&From=${From}&To=${To}`,
    {
      isBackground: true,
    }
  );
};

export const closeNotification = async (NotificationID) => {
  return axios.get(
    `${BASE_URL}/Notifications/V1/Close?NotificationID=${NotificationID}`
  );
};

// Claims
export const fetchClaims = async () => {
  return axios.get(`${BASE_URL}/ClaimManagement/V1/Claims`);
};

export const getClaimByID = async (ClaimID) => {
  return axios.get(`${BASE_URL}/ClaimManagement/V1/Claims/:${ClaimID}`);
};

export const publishClaim = async (ClaimID) => {
  return axios.get(`${BASE_URL}/ClaimManagement/V1/Publish?ClaimID=${ClaimID}`);
};

export const archiveClaim = async (ClaimID) => {
  return axios.get(`${BASE_URL}/ClaimManagement/V1/Archive?ClaimID=${ClaimID}`);
};

export const cancelClaim = async (ClaimID) => {
  return axios.get(`${BASE_URL}/ClaimManagement/V1/Cancel?ClaimID=${ClaimID}`);
};

export const precheckClaimGet = async (ClaimID) => {
  return axios.get(`${BASE_URL}/ClaimManagement/V1/Precheck?ClaimID=${ClaimID}`);
};

export const createClaim = async (Type, RelatedAssets, Title) =>
  axios.post(
    `${BASE_URL}/ClaimManagement/V1/Create`,
    {
      Type,
      ContractID: "51aa9b61-edec-42b7-b98a-a232110898ff",
      RelatedAssets,
      Title,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

export const setAttribute = async (ClaimID, UserTitle, UserDescription) => {
  return axios.post(
    `${BASE_URL}/ClaimManagement/V1/SetAttribute?ClaimID=${ClaimID}`,
    {
      UserTitle,
      UserDescription,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};

export const attachData = async (
  ClaimID,
  AssetID,
  From,
  To,
  Filename,
  Metric
) => {
  return axios.get(`${BASE_URL}/ClaimManagement/V1/AttachData`, {
    params: {
      ClaimID,
      AssetID,
      Metric,
      From,
      To,
      Type: "CSV",
      Filename,
    },
  });
};

export const uploadFile = async (filename, formData, ClaimID, Type) => {
  console.log(`Uploading file ${filename} with type ${Type}`);
  return axios.put(
    `${BASE_URL}/ClaimManagement/V1/Upload/${filename}?ClaimID=${ClaimID}`,
    formData
  );
};

export const downloadFile = async (ClaimID, DownloadKey) => {
  return axios.get(
    `${BASE_URL}/ClaimManagement/V1/Download?ClaimID=${ClaimID}&DownloadKey=${DownloadKey}`,
    {
      responseType: "arraybuffer",
    }
  );
};

// Assets
export const fetchAssets = async () => {
  return axios.get(`${BASE_URL}/Assets`);
};

export const getAssetByID = async (AssetID) => {
  return axios.get(`${BASE_URL}/Assets/:${AssetID}`);
};

// Contracts
export const getContracts = async () => {
  return axios.get(`${BASE_URL}/Contracts`);
};

export const getContractByID = async (ContractID) => {
  return axios.get(`${BASE_URL}/Contracts/:${ContractID}`);
};
