import React, { useEffect } from "react";
import axios from "axios";
import { Navigate, useSearchParams } from "react-router-dom";
import qs from "qs";
import { useAuth } from "../context/useAuth";

import process from "process";

function storeAuth(response, setToken) {
  sessionStorage.setItem("isAuth", true);
  sessionStorage.setItem("id_token", response.data.id_token);
  sessionStorage.setItem("access_token", response.data.access_token);
  sessionStorage.setItem("refresh_token", response.data.refresh_token);
  sessionStorage.setItem("expires_in", response.data.expires_in);
  setToken({ token: response.data.access_token });
  console.log("auth stored");
}

let _redirect_uri = "";
if (process.env.REACT_APP_STAGE === "rel") {
  _redirect_uri = "https://mu.claimmanagement.tpoc.datadrivenx.com/evalauth";
}
if (process.env.REACT_APP_STAGE === "debug") {
  _redirect_uri = "https://localhost:3000/evalauth";
}

function EvalAuth() {
  const { setToken } = useAuth();
  const [searchParams] = useSearchParams();
  const thecode = searchParams.get("code");

  let isAuthed = sessionStorage.getItem("isAuth");

  useEffect(() => {
    let count = sessionStorage.getItem("counter");
    if (!count) count = 0;
    count++;
    sessionStorage.setItem("counter", count);

    if (!thecode) return <Navigate to="/failure" />;

    let url =
      "https://demoddx.auth.eu-central-1.amazoncognito.com/oauth2/token";

    const data = {
      grant_type: "authorization_code",
      code: thecode,
      client_id: "61n6g08925tvempu71tfvv5cfv",
      redirect_uri: _redirect_uri,
    };

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify(data),
      url,
    };

    if (!isAuthed) {
      fetchToken(options);
    }
  }, []);

  const fetchToken = async (options) => {
    try {
      const response = await axios(options);
      storeAuth(response, setToken);
    } catch (e) {
      console.log("isAuthed", isAuthed);
      return <Navigate to="/login" />;
    }
  };

  if (isAuthed) return <Navigate to="/" />;
  return <div>you are logged on</div>;
}

export default EvalAuth;
