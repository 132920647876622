import React from "react";
//import Pager from "../pager/Pager";

/**
 * Card component - Represents a card with a title, subtitle, content, and optional footer.
 *
 * @param {Object} props - The props object for the Card component
 * @param {string} props.title - The title of the card
 * @param {string} props.subtitle - The subtitle of the card
 * @param {ReactNode} props.children - The content of the card
 * @param {ReactNode} props.footer - The footer of the card
 * @returns {JSX.Element} - The Card component
 */
const Card = ({ title, subtitle, children, footer, count, Pager }) => {
  return (
    <div className="card mw-100 overflow-hidden d-flex flex-column">
      <div className="card__header">
        <div className="d-flex justify-content-between align-items-start">
          <div className="">
            <div className="d-flex align-items-center gap-2">
              <h3 className="card__title">{title}</h3>
              {count ? (
                <span className="badge has-bgColor-primary">{count}</span>
              ) : null}
            </div>
            <p className="card__subline">{subtitle}</p>
          </div>
          {Pager ? <Pager /> : null}
        </div>
      </div>
      <div className="card__content flex-1 overflow-auto">{children}</div>
      {footer ? <div className="card__footer">{footer}</div> : null}
    </div>
  );
};

export default Card;
