import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Breadcrumb } from "../breadcrumb/Breadcrumb";
import "./Navigation.scss";

// TODO: need to add i18n labels
const navigation = [
  {
    menuIcon: "home",
    route: "/",
    hasAccess: true,
    subMenu: {},
  },
  {
    menuIcon: "graph",
    route: "/alarm-list",
    hasAccess: true,
    subMenu: {},
  },
  {
    menuIcon: "folder",
    route: "/claim-list",
    hasAccess: true,
    subMenu: {},
  },
];

function Navigation() {
  const [mainMenu] = useState(navigation);

  const navLinkStyles = ({ isActive }) => {
    return {
      fontWeight: isActive ? "bold" : "normal",
      color: isActive ? "#006FE6" : "#323232",
      borderLeftColor: isActive ? "#006FE6" : "#F6F6F6",
      borderLeftWidth: "3px",
      borderLeftStyle: "solid",
      padding: "4px 12px",
      display: "inline-block",
      background: isActive ? "#FFFFFF" : "#F6F6F6",
    };
  };

  const configureSubMenu = (subMenu) => {
    if (Object.keys(subMenu).length) {
      return (
        <div className="menu__items">
          <a className="menu__item header" href="#">
            {subMenu.header}
          </a>
          {subMenu.subMenuOptions.map((subMenuItem, index) => (
            <NavLink
              to={subMenuItem.route}
              key={"submenu-" + index}
              className="menu__item link"
            >
              {subMenuItem.menuItem}
            </NavLink>
          ))}
        </div>
      );
    }
  };

  const mainMenuList = mainMenu.map((menu, i) => {
    const dom = (
      <li key={"mainmenu-" + i}>
        <NavLink style={navLinkStyles} className="link" to={menu.route}>
          <span
            aria-hidden="true"
            className={"iconMdsp " + menu.menuIcon}
          ></span>
        </NavLink>
        {configureSubMenu(menu.subMenu)}
      </li>
    );
    return menu.hasAccess ? dom : "";
  });

  return (
    <>
      <div id="_mscontent" className="mdsp mdsp-defaults">
        <div className="appWrapper">
          <section className="appHeader">
            <div className="appHeader__breadcrumb">
              <Breadcrumb />
            </div>
          </section>
        </div>
      </div>
      <nav className="navigation">
        <ul>{mainMenuList}</ul>
      </nav>
    </>
  );
}

export { Navigation };
