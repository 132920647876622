import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { getAssets } from "../../features/assets/assetsSlice";
import { getClaims } from "../../features/claims/claimsSlice";
import { getNotifications } from "../../features/notifications/notificationsSlice";
import { Navigation } from "../navigation/Navigation";
import NavBar from "../NavigationBar/NavBar";
import "./Layout.scss";

function Layout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssets());
    dispatch(getClaims());
    dispatch(getNotifications());

    const intervalId = setInterval(() => {
      dispatch(getNotifications());
    }, 2000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <div className="ddx-layout">
      <NavBar />
      <Navigation />
      <div className="main">
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
