import { ResponsivePie } from "@nivo/pie";

const types = (type = "payment") =>
  ({
    payment: (
      <path
        fill="#969696"
        d="M417 167h13q40 0 77 4l40 5q89 13 143 41 57 30 60 70l1 62q74 15 117 42 46 27 49 62l1 131q0 30-31.5 55.5T799 682l-6 2-1 67q0 41-57 72-54 30-146 44l-22 3q-52 6-108 6t-108-6l-22-3q-89-13-144-42-56-30-60-69V620q3-29 35.5-53t88.5-41l-6-1q-71-15-113-41-43-28-46-62V287q3-39 56-68 51-28 136-41l33-5q43-5 86-6h22zm323 529q-50 29-137 43l-36 6q-52 6-108 6-45 0-87-4l-43-5q-107-16-162-53v62q0 17 28.5 33.5T274 813l18 4q29 7 61 11l22 2q84 8 167 0l22-2q31-4 62-10l18-5q47-12 75.5-27.5T750 754l1-60-11 2zm-573-70q0 16 26 31.5t71 28.5l28 6q22 5 45 8l38 5q72 7 145 2-22-1-44-4l-22-3q-89-13-143-42-57-29-60-69l-1-19q-39 12-61 26.5T167 626zm709-104q-51 34-148 51l-36 5q-52 6-108 6-45 0-87-4l-43-5q-83-12-136-38l-26-3v50q0 17 28.5 33.5T399 646l18 5q29 6 61 10l23 2q83 8 166 0l22-2q31-4 62-10l18-5q47-11 75.5-27t31.5-32v-65zM751 392v25q0 41-57 73-55 30-147 43l-22 3-22 2h-2q73 7 146 2l42-4q25-3 47-7l33-8q49-12 78-28.5t29-33q0-16.5-26-32.5t-71-28l-10-3-18-4zm-42-37q-51 35-147 51l-37 5q-52 6-108 6-45 0-87-4l-43-5q-107-16-162-53v62q0 17 29 33.5t78 29.5l18 4q29 6 62 10l22 3q83 8 166 0l23-3q31-4 61-10l18-4q47-12 75.5-28t31.5-32v-65zM417 209q-32 0-63 2l-42 4q-25 3-47 7l-33 7q-49 13-78 29.5t-29 33q0 16.5 26 32.5t71 28l28 7q23 5 45 8l39 4q73 7 146 2l43-4q24-3 46-7l33-8q50-12 78.5-28.5t28.5-33q0-16.5-26-32.5t-71-28l-28-7q-22-5-45-8l-39-5q-41-3-83-3z"
      />
    ),
    alert: (
      <path
        fill="#969696"
        d="M867 834L480 149 112 834h755zm42 0q0 17-12.5 29.5T867 876H112q-11 0-21-5.5t-15.5-15Q70 846 70 835t5-21l369-685q5-10 15-16t21-6q11 0 21 6t16 15l386 686q6 9 6 20zM459 667h42v84h-42v-84zm0-375h42v292h-42V292z"
      />
    ),
  }[type]);

const CenteredMetric =
  (type) =>
  ({ dataWithArc, centerX, centerY, size = 75 }) => {
    let total = 0;
    dataWithArc.forEach((datum) => {
      total += datum.value;
    });
    return (
      <svg
        x={centerX - size / 2}
        y={centerY - size / 2}
        textAnchor="middle"
        dominantBaseline="central"
        viewBox="0 0 1000 1000"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        {types(type)}
      </svg>
    );
    /* return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "52px",
        fontWeight: 600,
      }}
    >
      {total}
    </text>
  ); */
  };

const Chart = ({ data, icon }) => (
  <div className="mw-100" style={{ height: "260px" }}>
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 100, bottom: 40, left: 0 }}
      innerRadius={0.85}
      padAngle={2}
      activeOuterRadiusOffset={8}
      enableArcLinkLabels={false}
      enableArcLabels={false}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      legends={[
        {
          anchor: "top-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 8,
          itemWidth: 100,
          itemHeight: 16,
          itemTextColor: "#475569",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 16,
          symbolShape: "square",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#0f172a",
              },
            },
          ],
        },
      ]}
      colors={{ datum: "data.color" }}
      theme={{
        fontFamily: "Siemens Sans",
      }}
      layers={["arcs", "arcLabels", "legends", CenteredMetric(icon)]}
    />
  </div>
);

export default Chart;
