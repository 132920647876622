export const statusClass = (contractStatus) => {
  if (contractStatus === "") {
    return;
  }
  switch (contractStatus) {
    case 0:
      return "good";
    case 1:
      return "fair";
    case 2:
      return "bad";
    default:
      return "na";
  }
};

export const healthText = (contractStatus) => {
  if (contractStatus === "") {
    return;
  }
  switch (contractStatus) {
    case 0:
      return "GOOD";
    case 1:
      return "FAIR";
    case 2:
      return "BAD";
    default:
      return "N/A";
  }
};
