import React from "react";

const Pager = ({ currentPage, onPageChange, itemsPerPage, totalItems }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="pager d-flex align-items-center gap-2">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        className={`button button--ghost has-icon-only ${
          currentPage === 1 ? "is-disabled" : ""
        }`}
        disabled={currentPage === 1}
      >
        <span class="iconMdsp arrowSingleLeft" aria-hidden="true" />
      </button>

      <span class="pager__page is-current">
        {currentPage} / {totalPages}
      </span>

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`button button--ghost has-icon-only ${
          currentPage === totalPages ? "is-disabled" : ""
        }`}
      >
        <span class="iconMdsp arrowSingleRight" aria-hidden="true" />
      </button>
    </div>
  );
};

export default Pager;
