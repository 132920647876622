import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import styles from "./CreateAlarmLayout.module.scss";
import { useSelector } from "react-redux";

function CreateAlarmLayout() {
  const title = useSelector((state) => state.claims?.selectedItem?.Title);

  return title ? (
    <div id="create-alarm-layout" className={styles.pageLayout}>
      <div className={styles.header}>
        <div className={"has-color-base450 " + styles.heading}>{title}</div>
      </div>
      <div
        className={
          "has-borderColor-base750 has-bgColor-base1000 " + styles.section
        }
      >
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to="/alarm-list" />
  );
}

export default CreateAlarmLayout;
