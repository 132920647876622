import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createDownloadLink,
  readFileAsArrayBuffer,
} from "../../utils/FileUtils";

import {
  fetchClaims,
  createClaim as CreateClaimService,
  archiveClaim as ArchiveClaimService,
  cancelClaim as CancelClaimService,
  setAttribute as SetAttributeService,
  precheckClaimGet as PreCheckClaimServiceGet,
  attachData as AttachDataService,
  uploadFile as UploadFileService,
  downloadFile as DownloadFileService,
  publishClaim as PublishClaimService,
} from "../../apis/apis";
import { toast } from "react-hot-toast";

export const getClaims = createAsyncThunk(
  "claims/fetch",
  async (_, { dispatch, getState }) => {
    const response = await fetchClaims();
    const sortedClaims = response.data.sort((a, b) => {
      return new Date(b.CreationTime) - new Date(a.CreationTime);
    });

    return sortedClaims;
  }
);

export const createClaim = createAsyncThunk(
  "claims/create",
  async ({ Type, RelatedAssets, Title }, { rejectWithValue }) => {
    const response = await CreateClaimService(Type, RelatedAssets, Title);
    if (response.data.ClaimID) {
      return response.data;
    } else {
      return rejectWithValue(
        response.data.message || "Claim cannot be created"
      );
    }
  }
);

export const archiveClaim = createAsyncThunk(
  "claims/archive",
  async (ClaimID, { rejectWithValue }) => {
    const response = await ArchiveClaimService(ClaimID);
    if (response.data.ClaimID) {
      return response.data;
    } else {
      return rejectWithValue(
        response.data.message || "Claim cannot be archived"
      );
    }
  }
);

export const cancelClaim = createAsyncThunk(
  "claims/cancel",
  async (ClaimID, { rejectWithValue }) => {
    const response = await CancelClaimService(ClaimID);
    if (response.data.ClaimID) {
      return response.data;
    } else {
      return rejectWithValue(
        response.data.message || "Claim cannot be cancelled"
      );
    }
  }
);

export const precheckClaimPost = createAsyncThunk(
  "claims/precheckClaimPost",
  async ({ ClaimID, UserTitle, UserDescription }, { rejectWithValue }) => {
    const response = await SetAttributeService(
      ClaimID,
      UserTitle,
      UserDescription
    );


    if (response.data.ClaimID) {
      const res = await PreCheckClaimServiceGet(ClaimID);
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(
          res.data.message || "Claim cannot be pre-checked"
        );
      }
    } else {
      return rejectWithValue(
        response.data.message || "Attribute cannot be set, pre-check failed"
      );
    }
  }
);

export const publishClaim = createAsyncThunk(
  "claims/publish",
  async (ClaimID, { rejectWithValue }) => {
    const response = await PublishClaimService(ClaimID);
    if (response.data.ClaimID) {
      return response.data;
    } else {
      return rejectWithValue(
        response.data.message || "Claim cannot be published"
      );
    }
  }
);

export const setAttribute = createAsyncThunk(
  "claims/setAttribute",
  async ({ ClaimID, UserTitle, UserDescription }, { rejectWithValue }) => {
    const response = await SetAttributeService(
      ClaimID,
      UserTitle,
      UserDescription
    );
    if (response.data.ClaimID) {
      return response.data;
    } else {
      return rejectWithValue(
        response.data.message || "Attribute cannot be set"
      );
    }
  }
);

export const attachData = createAsyncThunk(
  "claims/attach",
  async ({ ClaimID, AssetID, From, To, Files }, { rejectWithValue }) => {
    try {
      const attachmentSet = [];
      const promises = Files.map(({ Metric, Name }) =>
        AttachDataService(ClaimID, AssetID, From, To, Name, Metric).then(
          (response) => {
            if (response.data.ClaimID) {
              response.data.Attachments.forEach((attachment) => {
                if (
                  !attachmentSet.some(
                    (a) => a.downloadkey === attachment.downloadkey
                  )
                ) {
                  attachmentSet.push(attachment);
                }
              });
              toast.success(`Metric added (${Name})`);
            } else {
              console.log(response.data);
              toast.error(
                `${response.data.message || response.data || "Cannot add metric"
                } (${Name})`
              );
            }
          }
        )
      );
      await Promise.all(promises);
      return Array.from(attachmentSet);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const uploadFile = createAsyncThunk(
  "claims/uploadFile",
  async ({ acceptedFiles, ClaimID }, { rejectWithValue }) => {
    const readFile = async (file) => {
      let buffer = await readFileAsArrayBuffer(file);
      return await UploadFileService(file.name, buffer, ClaimID, file.type);
    };

    let keys = Object.keys(acceptedFiles);
    const result = [];
    for (const key of keys) {
      let file = acceptedFiles[key];
      const data = await readFile(file);
      result.push(data);
    }

    const response = result[result.length - 1];

    if (response.data.ClaimID) {
      return response.data;
    } else {
      return rejectWithValue(response.data || "File cannot be uploaded");
    }
  }
);

export const downloadFile = createAsyncThunk(
  "claims/downloadFile",
  async ({ ClaimID, DownloadKey, FileName }, { rejectWithValue }) => {
    const response = await DownloadFileService(ClaimID, DownloadKey);
    console.log(response.data);
    if (response.data) {
      createDownloadLink(response, FileName);
      return response.data;
    } else {
      return rejectWithValue(
        response.data.message || "File cannot be downloaded"
      );
    }
  }
);

export const claimsSlice = createSlice({
  name: "claims",
  initialState: {
    value: 0,
    loading: false,
    loadingHours: false,
    loadingLogs: false,
    loadingAttributes: false,
    loadingData: false,
    error: null,
    items: [],
    selectedItem: null,
  },
  reducers: {
    selectClaim: (state, action) => {
      state.selectedItem = state.items.find(
        (item) => item.ClaimID === action.payload
      );
    },
  },
  extraReducers: (builder) => {
    const setError = (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.loadingHours = false;
      state.loadingLogs = false;
      state.loadingAttributes = false;
      state.loadingData = false;
    };
    const setLoading = (state, action) => {
      state.loading = true;
    };
    const updateItem = (state, action) => {
      state.items = state.items.map((item) =>
        item.ClaimID === action.payload.ClaimID ? action.payload : item
      );

      state.selectedItem = action.payload;
      if (action.payload.Precheck !== undefined) {
        state.selectedItem.claimPreCheckDetails = action.payload.Precheck !== undefined ? action.payload.Precheck : (
          action.payload.message ? action.payload.message : action.payload
        );
      }
      state.loading = false;
      state.loadingAttributes = false;
    };

    const updatePreCheckItem = (state, action) => {
      state.selectedItem.claimPreCheckDetails = action.payload.Precheck !== undefined ? action.payload.Precheck : (
        action.payload.message ? action.payload.message : action.payload
      );
      state.loading = false;
      state.loadingAttributes = false;
    };

    builder.addCase(getClaims.fulfilled, (state, action) => {
      state.items = action.payload;
      state.loading = false;
    });
    builder.addCase(getClaims.pending, setLoading);
    builder.addCase(getClaims.rejected, setError);
    builder.addCase(createClaim.fulfilled, (state, action) => {
      state.items = [action.payload, ...state.items];
      state.selectedItem = action.payload;
      state.loading = false;
    });
    builder.addCase(createClaim.pending, setLoading);
    builder.addCase(createClaim.rejected, setError);
    builder.addCase(archiveClaim.fulfilled, updateItem);
    builder.addCase(archiveClaim.pending, setLoading);
    builder.addCase(archiveClaim.rejected, setError);
    builder.addCase(cancelClaim.fulfilled, updateItem);
    builder.addCase(cancelClaim.pending, setLoading);
    builder.addCase(cancelClaim.rejected, setError);
    builder.addCase(precheckClaimPost.fulfilled, updatePreCheckItem);
    builder.addCase(precheckClaimPost.pending, setLoading);
    builder.addCase(precheckClaimPost.rejected, setError);
    builder.addCase(publishClaim.fulfilled, updateItem);
    builder.addCase(publishClaim.pending, setLoading);
    builder.addCase(publishClaim.rejected, setError);

    builder.addCase(setAttribute.fulfilled, updateItem);
    builder.addCase(setAttribute.pending, (state, action) => {
      state.loadingAttributes = true;
    });
    builder.addCase(setAttribute.rejected, setError);

    builder.addCase(attachData.fulfilled, (state, action) => {
      if (action.payload.length) {
        state.items = state.items.map((item) =>
          item.ClaimID === state.selectedItem.ClaimID
            ? { ...item, Attachments: action.payload }
            : item
        );
        state.selectedItem = {
          ...state.selectedItem,
          Attachments: action.payload,
        };
      }
      state.loadingData = false;
    });
    builder.addCase(attachData.pending, (state, action) => {
      state.loadingData = true;
    });
    builder.addCase(attachData.rejected, setError);

    builder.addCase(uploadFile.fulfilled, updateItem);
    builder.addCase(uploadFile.pending, setLoading);
    builder.addCase(uploadFile.rejected, setError);
    builder.addCase(downloadFile.fulfilled, (state, action) => {
      console.log(action.payload);
    });
    builder.addCase(downloadFile.pending, setLoading);
    builder.addCase(downloadFile.rejected, setError);
  },
});

export const { selectClaim } = claimsSlice.actions;

export default claimsSlice.reducer;
