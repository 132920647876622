import { Toaster } from "react-hot-toast";

const Toast = () => {
  return (
    <Toaster
      toastOptions={{
        position: "top-right",
        duration: 5000,
        style: {
          fontFamily: "Siemens Sans",
          border: "1px solid",
          borderRadius: "3px",
        },
        error: {
          style: {
            color: "rgba(var(--color-error-dark), 1)",
            backgroundColor: "rgba(var(--color-error-lighter), 1)",
            borderColor: "rgba(var(--color-error-light), 1)",
          },
          iconTheme: {
            primary: "rgba(var(--color-error-dark), 1)",
          },
        },
        success: {
          style: {
            color: "rgba(var(--color-success-dark), 1)",
            backgroundColor: "rgba(var(--color-success-lighter), 1)",
            borderColor: "rgba(var(--color-success-light), 1)",
          },
          iconTheme: {
            primary: "rgba(var(--color-success-dark), 1)",
          },
        },
      }}
    />
  );
};

export default Toast;
