import React, { useEffect } from "react";

let _logout_uri = "";
if (process.env.REACT_APP_STAGE === "rel") {
  _logout_uri =
    "https://demoddx.auth.eu-central-1.amazoncognito.com/logout?client_id=61n6g08925tvempu71tfvv5cfv&logout_uri=https://mu.claimmanagement.tpoc.datadrivenx.com/";
}

if (process.env.REACT_APP_STAGE === "debug") {
  _logout_uri =
    "https://demoddx.auth.eu-central-1.amazoncognito.com/logout?client_id=61n6g08925tvempu71tfvv5cfv&logout_uri=https://localhost:3000/";
}

function Logout() {
  useEffect(() => {
    logout();
  }, []);

  const logout = async (options) => {
    sessionStorage.clear();
    window.location.href = _logout_uri;
    console.log("logged out");
  };

  return <div>you are logged out</div>;
}

export default Logout;
