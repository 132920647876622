import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchNotifications,
  fetchNotificationDetail,
  closeNotification as closeNotificationService,
} from "../../apis/apis";

export const getNotifications = createAsyncThunk(
  "notifications/fetch",
  async (_, { dispatch, getState }) => {
    const response = await fetchNotifications();
    const { items } = getState().notifications;
    const { Notifications } = response.data;
    const sortedNotifications = Notifications?.CompanyNotifications.sort(
      (a, b) => {
        return new Date(b.TimeStamp) - new Date(a.TimeStamp);
      }
    );

    const notificationsWithData =
      items?.length !== sortedNotifications?.length
        ? await Promise.all(
            sortedNotifications.map(async (notification) => {
              const { AssetID, MetricID, TimeStamp } = notification.Payload;
              const response = await fetchNotificationDetail(
                AssetID,
                MetricID,
                TimeStamp.S,
                TimeStamp.S
              );
              return { ...notification, Detail: response.data[0] };
            })
          )
        : items;

    return notificationsWithData;
  }
);

export const closeNotification = createAsyncThunk(
  "notifications/close",
  async (NotificationID, { rejectWithValue }) => {
    const response = await closeNotificationService(NotificationID);
    if (response.data) {
      return { data: response.data, NotificationID };
    } else {
      return rejectWithValue(
        response.data.message || "Notification cannot be closed"
      );
    }
  }
);

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    value: 0,
    status: "idle",
    error: null,
    items: [],
    selectedItem: null,
  },
  reducers: {
    selectNotification: (state, action) => {
      state.selectedItem = state.items.find(
        (item) => item.NotificationID === action.payload
      );
    },
  },
  extraReducers: (builder) => {
    const setError = (state, action) => {
      state.error = action.error.message;
      state.status = "failed";
    };
    const setLoading = (state, action) => {
      if (state.status === "idle") {
        state.status = "loading";
      }
    };

    builder.addCase(getNotifications.fulfilled, (state, action) => {
      if (state.items.length !== action.payload.length) {
        state.items = action.payload;
      }
      state.status = "succeeded";
    });
    builder.addCase(getNotifications.pending, setLoading);
    builder.addCase(getNotifications.rejected, setError);
    builder.addCase(closeNotification.fulfilled, (state, action) => {
      const { NotificationID } = action.payload;
      state.items = state.items.filter(
        (item) => item.NotificationID !== NotificationID
      );
      state.status = "succeeded";
    });
    builder.addCase(closeNotification.pending, setLoading);
    builder.addCase(closeNotification.rejected, setError);
  },
});

export const { selectNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
