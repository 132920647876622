import React, { useState } from "react";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Notifications = ({ pageSize, page, setPage }) => {
  const notifications = useSelector((state) => state.notifications.items);
  const status = useSelector((state) => state.notifications.status);
  const error = useSelector((state) => state.notifications.error);
  const assets = useSelector((state) => state.assets.items);

  const severityColor = (severity) => {
    if (severity <= 99) {
      return "has-color-info-dark";
    } else if (severity <= 199) {
      return "has-color-warning";
    } else if (severity <= 255) {
      return "has-color-error";
    } else {
      return "has-color-info-dark";
    }
  };

  if (status === "loading") {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex flex-1">
        <span className="busyIndicator is-shown">
          <span className="busyIndicator__ring" />
        </span>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!notifications || notifications.length === 0) {
    return (
      <div className="emptyState emptyState--frameless">
        <div className="emptyState__visual">
          <span aria-hidden="true" className="iconMdsp filled notification" />
        </div>
        <h2 className="emptyState__title">No notifications available</h2>
        <div className="emptyState__description">
          Received notifications will be listed here
        </div>
      </div>
    );
  }

  return (
    <div className="listNavigation listNavigation--alternateBgColor flex-none">
      <div className="listNavigation__lists">
        <ul className="listNavigation__list">
          {notifications
            ?.slice((page - 1) * pageSize, page * pageSize)
            .map((notification) => {
              const { NotificationID, TimeStamp, Payload, Detail } =
                notification;
              return (
                <li className="list__item" key={NotificationID}>
                  <Link
                    className="item__link"
                    title="View details"
                    to="/alarm-list"
                    state={notification}
                  >
                    {/* <div className="item__icon">
                    <span
                      className={`iconMdsp notification ${severityColor(
                        Detail.Severity
                      )}`}
                      aria-hidden="true"
                    />
                  </div> */}
                    <div className="item__content">
                      <h4 className="label">
                        {assets ? assets[Payload?.AssetID].Name : null}
                      </h4>
                      <div className="description d-flex gap-1">
                        <span>
                          {new Date(TimeStamp).toLocaleDateString("en-GB")}
                        </span>
                        <span>
                          {new Date(TimeStamp).toLocaleTimeString("en-GB")}
                        </span>
                      </div>
                    </div>
                    <div className="item__metaInfo">
                      <span
                        className={`iconMdsp exclamationDiamond filled ${severityColor(
                          Detail.Severity
                        )}`}
                        aria-hidden="true"
                      />
                    </div>
                    <div className="item__metaInfo">
                      <span
                        className="iconMdsp arrowSingleRight"
                        aria-hidden="true"
                      />
                    </div>
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default Notifications;
