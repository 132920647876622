export function getFilenameFromContentDisposition(contentDisposition) {
  const regex = /filename=([^;]*)/;
  const matches = regex.exec(contentDisposition);

  if (matches !== null && matches.length > 1) {
    return matches[1];
  }
  return "filename";
}

export function readFileAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsArrayBuffer(file);
  });
}

export function createDownloadLink(response, FileName) {
  const url = window.URL.createObjectURL(
    new Blob([response.data], {
      type: `${response.headers["content-type"]}`,
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", FileName);
  document.body.appendChild(link);
  link.click();
}
