import React from 'react';

function SpinnerApp() {
  return (
    <div className="busyIndicator busyIndicator--withOverlay is-shown">
      <div className="busyIndicator__ring busyIndicator__ring--medium"></div>
      <div className="busyIndicator__overlay"></div>
    </div>
  );
}

export default SpinnerApp;
