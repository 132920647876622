import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import CreateAlarmLayout from "../create-alarm-layout/CreateAlarmLayout";
import SpinnerApp from "../spinners/SpinnerApp";
import Login from "../../logindashboard/Login";
import Layout from "../layout/Layout";
import ProtectedRoute from "./ProtectedRoutes";
import EvalAuth from "../../logindashboard/EvalAuth";
import Logout from "../../logindashboard/Logout";
import Home from "../home/Home";
import Alarms from "../alarms/Alarms";
import ClaimInformation from "../claim-information/ClaimInformation";
import Claims from "../claims/Claims";
import ClaimReport from "../claim-information/ClaimReport";

function AppRoutes() {
  return (
    <Suspense fallback={<SpinnerApp />}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/evalauth" element={<EvalAuth />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="alarm-list">
              <Route index element={<Alarms />} />
            </Route>
            <Route path="claim-list">
              <Route index element={<Claims />} />
              <Route path="create-claim" element={<CreateAlarmLayout />}>
                <Route index element={<ClaimInformation />} />
              </Route>
              <Route path="claim-report" element={<CreateAlarmLayout />}>
                <Route index element={<ClaimReport />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
