import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Dropzone from "../shared/dropzone/Dropzone";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./ClaimInformation.scss";
import Button from "../shared/button/Button";
import {
  archiveClaim,
  attachData,
  cancelClaim,
  precheckClaimPost,
  setAttribute,
} from "../../features/claims/claimsSlice";

const schema = Yup.object({
  title: Yup.string(),
  description: Yup.string(),
  alarmLog: Yup.boolean(),
  operatingHours: Yup.boolean(),
  /* reportingPeriod: Yup.number().when(["alarmLog", "operatingHours"], {
    is: (alarmLog, operatingHours) => alarmLog || operatingHours,
    then: Yup.number().required("Required"),
    otherwise: Yup.number(),
  }),
  reportingPeriodInterval: Yup.string().when(["alarmLog", "operatingHours"], {
    is: (alarmLog, operatingHours) => alarmLog || operatingHours,
    then: Yup.string().required("Required"),
    otherwise: Yup.string(),
  }), */
});

function getMilliseconds(interval) {
  switch (interval) {
    case "second":
      return 1000;
    case "minute":
      return 60 * 1000;
    case "hour":
      return 60 * 60 * 1000;
    case "day":
      return 24 * 60 * 60 * 1000;
    default:
      throw new Error(`Invalid reporting period interval: ${interval}`);
  }
}

function ClaimInformation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedClaim = useSelector((state) => state.claims.selectedItem);
  const assets = useSelector((state) => state.assets.items);

  const date = new Date(selectedClaim?.CreationTime).toLocaleDateString(
    "en-GB"
  );
  const time = new Date(selectedClaim?.CreationTime).toLocaleTimeString(
    "en-GB"
  );
  const relatedAssets = selectedClaim?.RelatedAssets;
  const { ClaimID, Attributes, CreationTime, Attachments } = selectedClaim;
  const { UserTitle, UserDescription } = Attributes;

  const { Metrics, UserAttachments } = Attachments?.reduce(
    (acc, curr) => {
      if (curr.description?.startsWith("System attached data-file")) {
        acc.Metrics.push(curr);
      } else {
        acc.UserAttachments.push(curr);
      }
      return acc;
    },
    { Metrics: [], UserAttachments: [] }
  );

  const initialValues = {
    title: UserTitle || "",
    description: UserDescription || "",
    alarmLog: false,
    operatingHours: false,
    reportingPeriod: 1,
    reportingPeriodInterval: "second",
  };

  function calculateDates(reportingPeriod, reportingPeriodInterval) {
    const end = new Date(CreationTime);
    const ms = reportingPeriod * getMilliseconds(reportingPeriodInterval);

    const startDate = new Date(end.getTime() - ms).toISOString();
    const endDate = end.toISOString();

    return { startDate, endDate };
  }

  const submitHandler = ({
    title,
    description,
    reportingPeriod,
    reportingPeriodInterval,
    alarmLog,
    operatingHours,
  }) => {
    const { startDate, endDate } = calculateDates(
      reportingPeriod,
      reportingPeriodInterval
    );

    dispatch(
      setAttribute({ ClaimID, UserTitle: title, UserDescription: description })
    );

    const dataAttributes = {
      ClaimID,
      AssetID: relatedAssets[0],
      From: startDate,
      To: endDate,
    };

    const files = [];

    if (alarmLog) {
      files.push({
        Name: "AlarmLog.csv",
        Metric: "964739fb-1c67-4a06-a37c-c72ff4462366",
      });
    }

    if (operatingHours) {
      files.push({
        Name: "OperatingHours.csv",
        Metric: "658c380e-6bc9-41e8-8cfb-0170dd7534e6",
      });
    }

    if (files.length)
      dispatch(
        attachData({
          ...dataAttributes,
          Files: files,
        })
      );

    navigate("/claim-list/claim-report");
  };

  const [showMore, setShowMore] = useState(false);
  return selectedClaim ? (
    <div className="claim-form">
      <h5 className="fw-bold mb-3">Incident Report</h5>
      <Formik
        initialValues={initialValues}
        // enableReinitialize={true}
        validationSchema={schema}
        onSubmit={submitHandler}
      >
        {({ values, errors, touched, isValid, handleChange }) => (
          <Form className="d-flex flex-column flex-1">
            <div className="d-flex">
              <div className="w-75 d-flex flex-column flex-1">
                <div className="information-container d-flex gap-4">
                  <div className="keyValueWrapper">
                    <span
                      className="keyValueWrapper__icon iconMdsp types"
                      aria-hidden="true"
                    />
                    <div className="keyValueWrapper__keyValue keyValueWrapper__keyValue--reverse">
                      <div className="keyValue__value">
                        {assets[relatedAssets[0]].Name}
                      </div>
                      <div className="keyValue__key">Affected asset</div>
                    </div>
                  </div>
                  <div className="keyValueWrapper">
                    <span
                      className="keyValueWrapper__icon iconMdsp calendar"
                      aria-hidden="true"
                    />
                    <div className="keyValueWrapper__keyValue keyValueWrapper__keyValue--reverse">
                      <div className="keyValue__value">{date}</div>
                      <div className="keyValue__key">Date of the incident</div>
                    </div>
                  </div>
                  <div className="keyValueWrapper">
                    <span
                      className="keyValueWrapper__icon iconMdsp clock"
                      aria-hidden="true"
                    />

                    <div className="keyValueWrapper__keyValue keyValueWrapper__keyValue--reverse">
                      <div className="keyValue__value">{time}</div>
                      <div className="keyValue__key">Time of the incident</div>
                    </div>
                  </div>
                </div>
                <div className="information-container d-flex gap-4">
                  <div className="d-flex flex-column">
                    <div className="inputGroup">
                      <label htmlFor="title" className="inputGroup__label">
                        Report title
                      </label>

                      <Field
                        type="text"
                        name="title"
                        id="title"
                        className="inputGroup__textInput"
                      />
                      {touched.title && errors.title && (
                        <div>{errors.title}</div>
                      )}
                    </div>
                    <div className="inputGroup">
                      <label
                        className="inputGroup__label"
                        htmlFor="description"
                      >
                        Description
                      </label>
                      <Field
                        id="description"
                        name="description"
                        as="textarea"
                        className="inputGroup__textarea"
                      />
                      <ErrorMessage
                        name="description"
                        className="inputGroup--error"
                      />
                    </div>
                  </div>
                  <div className="inputGroup">
                    <label htmlFor="dropzone" className="inputGroup__label">
                      Attach files
                    </label>
                    <Dropzone id="dropzone" />
                  </div>
                </div>
                <div className="information-container d-flex gap-4">
                  <div className="inputGroup">
                    <label className="inputGroup__label" htmlFor="period">
                      Reporting period before incident
                    </label>

                    <div className="d-flex gap-2">
                      <Field
                        type="number"
                        min="1"
                        name="reportingPeriod"
                        id="reportingPeriod"
                        className="inputGroup__textInput w-50"
                        onChange={handleChange}
                      />

                      <div className="selectWrapper flex-1">
                        <Field
                          as="select"
                          name="reportingPeriodInterval"
                          // id="reportingPeriodInterval"
                          className="inputGroup__select"
                          onChange={handleChange}
                        >
                          <option value="second">Seconds</option>
                          <option value="minute">Minutes</option>
                          <option value="hour">Hours</option>
                          <option value="day">Days</option>
                        </Field>
                      </div>
                    </div>

                    <div className="inputGroup__description">
                      {touched.reportingPeriod &&
                        errors.reportingPeriod &&
                        errors.reportingPeriod}
                      {touched.reportingPeriodInterval &&
                        errors.reportingPeriodInterval &&
                        errors.reportingPeriodInterval}
                    </div>
                  </div>
                  <div className="inputGroup">
                    <label className="inputGroup__label">
                      Asset state metrics
                    </label>

                    <div className="checkboxWrapper">
                      <Field
                        type="checkbox"
                        name="alarmLog"
                        id="alarmLog"
                      //className="inputGroup__checkbox"
                      />
                      <label htmlFor="alarmLog">Alarm log</label>
                    </div>
                  </div>
                  <div className="inputGroup">
                    <label className="inputGroup__label">
                      Production metrics
                    </label>

                    <div className="checkboxWrapper">
                      <Field
                        type="checkbox"
                        name="operatingHours"
                        id="operatingHours"
                      // className="inputGroup__checkbox"
                      />
                      <label htmlFor="operatingHours">Operating hours</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-25">
                <div className="information-container d-flex gap-4">
                  <div className="keyValueWrapper">
                    <span
                      className="keyValueWrapper__icon iconMdsp attachment"
                      aria-hidden="true"
                    />
                    <div className="keyValueWrapper__keyValue keyValueWrapper__keyValue--reverse">
                      {UserAttachments.length
                        ? UserAttachments?.map((item) => (
                          <div
                            className="keyValue__value"
                            key={item.downloadkey}
                          >
                            {item.filename}
                          </div>
                        ))
                        : "-"}
                      <div className="keyValue__key">Attachments</div>
                    </div>
                  </div>
                </div>
                <div className="information-container d-flex gap-4">
                  <div className="keyValueWrapper">
                    <span
                      className="keyValueWrapper__icon iconMdsp chartLine"
                      aria-hidden="true"
                    />
                    <div className="keyValueWrapper__keyValue keyValueWrapper__keyValue--reverse">
                      <div className="keyValue__value">
                        {Metrics.length
                          ? Metrics?.map((item) => (
                            <div key={item.downloadkey}>{item.filename}</div>
                          ))
                          : "-"}
                      </div>
                      <div className="keyValue__key">Metrics</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {selectedClaim.claimPreCheckDetails && selectedClaim.claimPreCheckDetails.message === undefined && selectedClaim.claimPreCheckDetails.explanation !== undefined ?
                <div className="information-container-precheck">
                  <div className="">
                    <div className="keyValue__key prechek_det_head">Pre-Check Details of Claim</div>
                    <div className="keyValue__value precheck_details"><span className="precheck_det_span">Confidence</span> : {selectedClaim.claimPreCheckDetails.confidence}</div>
                    <div className="keyValue__value precheck_details" style={selectedClaim.claimPreCheckDetails.result ? { "backgroundColor": "#23e123" } : { "backgroundColor": "#ff6969" }}><span className="precheck_det_span">Is Claim Insured</span> : {selectedClaim.claimPreCheckDetails.result}</div>
                    <div className="keyValue__value precheck_details"><span className="precheck_det_span">Claim Policy Paragraph</span> : {selectedClaim.claimPreCheckDetails.paragraph}</div>
                    <div className="keyValue__value precheck_details"><span className="precheck_det_span">Claim Policy Paragraph Details</span> : {selectedClaim.claimPreCheckDetails.paragraph_detail}</div>
                    <div className="keyValue__value precheck_details"><span className="precheck_det_span">Claim Check Explanation</span> : {showMore ? selectedClaim.claimPreCheckDetails.explanation : selectedClaim.claimPreCheckDetails.explanation.substring(0, 200) + (selectedClaim.claimPreCheckDetails.explanation.length > 200 ? "..." : "")}
                      {selectedClaim.claimPreCheckDetails.explanation.length > 200 ? <button className="show_more" onClick={(e) => { e.preventDefault(); setShowMore(!showMore); }}>{showMore ? "Show less" : "Show more"}</button> : ""}
                    </div>
                  </div> </div>
                : (selectedClaim.claimPreCheckDetails && selectedClaim.claimPreCheckDetails.message !== undefined ?
                  <div className="information-container-precheck">
                    <div className="keyValue__key prechek_det_head">Pre-Check Details of Claim</div>
                    <div className="keyValue__value precheck_details"><span className="precheck_det_span">Error Occured</span> : {selectedClaim.claimPreCheckDetails.message}</div>
                  </div> :
                  <div className="keyValue__key prechek_det_head">{selectedClaim.claimPreCheckDetails}</div>
                )}
            </div>

            <div className="footer d-flex justify-content-end gap-2 align-items-center align-self-end">

              <input type="button"
                value={"Precheck claim"}
                className="button button--secondary"
                onClick={() => {
                  console.log("IN Claim Information");
                  dispatch(precheckClaimPost({
                    ClaimID: selectedClaim?.ClaimID,
                    UserTitle: values.title,
                    UserDescription: values.description
                  }))
                }}
              />
              <Button
                label={"Cancel claim"}
                cssClass="button--ghost"
                callback={() => dispatch(cancelClaim(selectedClaim?.ClaimID))}
              />
              <Button
                label={"Archive claim"}
                cssClass="button--secondary"
                callback={() => dispatch(archiveClaim(selectedClaim?.ClaimID))}
              />
              <button
                type="submit"
                disabled={!isValid}
                className="button button--primary"
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  ) : (
    <Navigate to="/" />
  );
}

export default ClaimInformation;
