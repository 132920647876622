import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAssets } from "../../apis/apis";

function extractAssetMap(objArray) {
  const result = {};
  objArray.forEach((obj) => {
    const { AssetID, Name } = obj;
    if (result[AssetID]) {
      result[AssetID].Name = Name;
    } else {
      result[AssetID] = { Name };
    }
  });

  return result;
}

export const getAssets = createAsyncThunk("assets/fetch", async () => {
  const response = await fetchAssets();

  return extractAssetMap(response.data);
});

export const assetsSlice = createSlice({
  name: "assets",
  initialState: {
    value: 0,
    loading: false,
    error: null,
    items: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAssets.fulfilled, (state, action) => {
      state.items = action.payload;
    });
  },
});

export const {} = assetsSlice.actions;

export default assetsSlice.reducer;
