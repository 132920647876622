import React from "react";

const Dialog = ({
  title,
  description,
  onClose,
  closeText,
  primaryText,
  primaryAction,
}) => {
  return (
    <div className="dialog is-shown dialog--confirm confirm--alert">
      <div className="dialog__overlay" />
      <div className="dialog__container">
        <div className="dialog__background">
          <section className="dialog__content">
            <h1 className="confirm__headline">{title}</h1>
            <p>{description}</p>
          </section>
          <footer className="dialog__footer">
            <button className="button button--secondary" onClick={onClose}>
              {closeText}
            </button>
            <button className="button button--primary" onClick={primaryAction}>
              {primaryText}
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
