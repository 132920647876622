import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";

import CustomToggle from "../custom-toggle-bootstrap/CustomToggle";
import { useSelector, useDispatch } from "react-redux";
import {
  archiveClaim,
  cancelClaim,
  selectClaim,
} from "../../features/claims/claimsSlice";

import "./Claims.scss";
import Button from "../shared/button/Button";
import { getClaimStateColor, groupByState } from "../../utils/ClaimUtils";
import Filter from "../shared/filter/Filter";
import Pager from "../shared/pager/Pager";

const PAGE_SIZE = 10;

function Alarms() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const assets = useSelector((state) => state.assets.items);
  const claims = useSelector((state) => state.claims.items);

  const tags = groupByState(claims).map(({ label, id, color }) => ({
    value: id,
    label,
    color,
  }));
  const [selectedTags, setSelectedTags] = useState(tags);
  const [page, setPage] = useState(1);

  const filteredClaims = selectedTags.length
    ? claims.filter((item) =>
        selectedTags.some((tag) => item.State === tag.label)
      )
    : claims;

  const selectHandler = (ClaimID) => {
    dispatch(selectClaim(ClaimID));
    navigate("create-claim");
  };
  const reportHandler = (ClaimID) => {
    dispatch(selectClaim(ClaimID));
    navigate("claim-report");
  };

  useEffect(() => {
    setPage(1);
  }, [selectedTags]);

  const alarmsHtml = () => {
    return filteredClaims
      ?.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
      .map(
        ({
          ClaimID,
          Title,
          RelatedAssets,
          CreationTime,
          State,
          Attributes,
        }) => {
          const { UserTitle, UserDescription } = Attributes;
          return (
            <Accordion key={ClaimID} data-testid="accordion">
              <Card>
                <Card.Header>
                  <div className="accordion-header">
                    <div
                      className="column"
                      style={{ borderColor: getClaimStateColor(State) }}
                    >
                      <CustomToggle eventKey={ClaimID} />
                    </div>
                    <div className="column">
                      <p className="content d-flex gap-1">
                        <span>
                          {new Date(CreationTime).toLocaleDateString("en-GB")}
                        </span>
                        <span>
                          {new Date(CreationTime).toLocaleTimeString("en-GB")}
                        </span>
                      </p>
                      <p className="header">{Title}</p>
                    </div>
                    <div className="column flex-1">
                      <p className="content">Asset</p>
                      <p className="header">
                        {assets ? assets[RelatedAssets[0]].Name : null}
                      </p>
                    </div>
                    <div className="column">
                      <p className="content">State</p>
                      <p className="header">{State}</p>
                      {/* <span
                      class="badge badge--large badge--dark"
                      style={{ backgroundColor: getClaimStateColor(State) }}
                    >
                      {State}
                    </span> */}
                    </div>
                    <div className="column" />
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey={ClaimID}>
                  <div className="card-body ">
                    <div className="column" />

                    <div className="column">
                      <p className="content">Title</p>
                      <p className="header">{UserTitle || "-"}</p>
                    </div>
                    <div className="column">
                      <p className="content">Description</p>
                      <p className="header">{UserDescription || "-"}</p>
                    </div>
                    <div className="d-flex pe-4 pt-3 flex-1 align-items-start justify-content-end">
                      {State === "Created" ? (
                        <>
                          <Button
                            label={"Cancel claim"}
                            cssClass="button--ghost"
                            callback={() => dispatch(cancelClaim(ClaimID))}
                          />
                          <Button
                            label={"Archive claim"}
                            cssClass="button--secondary"
                            callback={() => dispatch(archiveClaim(ClaimID))}
                          />

                          <Button
                            label={"Select claim"}
                            cssClass="button--primary"
                            callback={() => selectHandler(ClaimID)}
                          />
                        </>
                      ) : null}
                      {State === "Published" ? (
                        <Button
                          label={"See report"}
                          cssClass="button--primary"
                          callback={() => reportHandler(ClaimID)}
                        />
                      ) : null}
                    </div>
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          );
        }
      );
  };

  return (
    <div id="payment-management" className="dynamic-payments ddx-content">
      <div
        className="container-fluid payment-details py-3"
        data-testid="payment-management"
      >
        <div className="d-flex justify-content-between align-items-center">
          {claims ? (
            <>
              <Filter tags={tags} setSelectedTags={setSelectedTags} />
              <Pager
                currentPage={page}
                onPageChange={setPage}
                itemsPerPage={PAGE_SIZE}
                totalItems={filteredClaims?.length}
              />
            </>
          ) : null}
        </div>
        {alarmsHtml()}
      </div>
    </div>
  );
}

export default Alarms;
